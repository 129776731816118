<template>
  <div id="AddUser" class="row mx-0">
    <div class="row mx-0">
      <div id="header" class="col-sm-12 col-md-12 color-bg-4">
        <Header
                :name="user.fullname"
                /> 
      </div>
      <div class="col-sm-12 col-md-12">
        <div id="content" class="row color-bg-4">
           <div id="" class="">
           <SideMenu :indexsec="6"/>
          </div>
          <div id="stn-edituser" class="stn_edituserAdd">
             <form
              @submit.prevent="
                submit(
                  data.name,
                  parent_form
                )
              "
            >
            <div class="div_EProduct">
              <p>EDITAR CATEGORIA</p>
              </div>

              <div class="row mx-0 row-two">
                <div class="EPF1_C1">
                  <label class="labelN " for="inputN">Nombre</label>
                  <b-form-input
                  
                    v-model="data.name"
                    required
                    pattern="[a-zA-ZñÑáéíóúÁÉÍÓÚ0-9\s]{2,254}"
                    id="inputN"
                    type="text"
                  />
                </div>
                <div class="">
                    <label class="labelR " for="inputR">Categoría padre</label>
                    <b-dropdown v-if="data.id == 1" disabled>
                      <template #button-content>
                        <div class="row"><span class="drop-text">NINGUNA</span></div> 
                      </template>

                     <b-dropdown-item @click="changeCategory(0, 'NINGUNA')">
                            NINGUNA
                      </b-dropdown-item>
                    </b-dropdown>
                     <b-dropdown v-else>
                      <template #button-content>
                        <div class="row"><span class="drop-text">{{data.parent_name}}</span></div> 
                      </template>

                       <div v-for="(item, key) in this.categories" :key="key">
                          <b-dropdown-item @click="changeCategory(item.id, item.name)">
                            {{item.name}}
                          </b-dropdown-item>
                       </div>
                    </b-dropdown>
                  </div>
              </div>

             <div class="row mx-0 row-one">
                <div class="div-error" v-if="this.status=='error'">
                    <p class="msg-error">{{this.msg}}</p>
                </div>
              </div>

              <div class="btn-form">
                <b-btn 
                  variant="outline-secondary"
                  class="editar-modal "
                  type="submit"
                  >GUARDAR</b-btn
                >
                <b-modal
                  id="modal-editar"
                  ref="modal-editar"
                  no-close-on-backdrop
                  centered
                  hide-footer
                  hide-header
                >
                  <div class="message-modal">
                    <img :src="IconSuccess" class="success" /><br />
                    <p class="text-modal">La categoría se edito correctamente</p>
                  </div>
                  <div class="">
                    <b-button class="btn-modal" @click="editarOtro()"
                      >CONFIRMAR</b-button
                    >
                    <b-button
                      type="button"
                      aria-label="Close"
                      class="close-modal"
                      @click="salir()"
                      >×</b-button
                    >
                  </div>
                </b-modal>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>


import Header from '../../components/Header';
import SideMenu from '../../components/SideMenu';
import IconSuccess from "../../assets/Vector.png";

import { mapActions } from "vuex";
export default {
  name: "EditCategory",
   components: {
        Header,
        SideMenu,
    },
  data() {
    return {
      status: "",
      msg: "",
      parent_form: "",
      
      IconSuccess: IconSuccess,
      element: 1,
      options: [  'Productos',
                 'Categorías',
                 'Marcas',
                 'Usuarios',],
    };
  },
  created() {
    let id = this.$route.params.id;
    let payload = {
      id: id,
      option: "category",
    };
    this.getInfoById(payload);
    this.getCategories('Categorías')

    this.parent_form = this.data.id_parent
    
  },
  computed: {
    user() {            
            return this.$store.getters['admin/getIdentity'];
        },
    data() {
      return this.$store.getters["main/data"];
    },
    categories() { //elimino la categoria actual para evitar lo de agregarse a si misma.
      let id = this.$route.params.id;
      let allctg =this.$store.getters["categories/data"];
      let result =[];
      let model;
      for(var i=0; i<allctg.length; i++){
        if(allctg[i].id != id && allctg[i].id_parent != id){
          if(allctg[i].id== 1){
             model ={
                        id: allctg[i].id,
                        id_parent: allctg[i].id_parent,
                        name: 'NINGUNA',
                        parent_name: allctg[i].parent_name
                      }
          }else{
            model ={
            id: allctg[i].id,
            id_parent: allctg[i].id_parent,
            name: allctg[i].name,
            parent_name: allctg[i].parent_name
          }
          }

          result.push(model);
        }
      }
      return result
    },
  },
  methods: {
    ...mapActions("main", ["getInfoById"]),
    ...mapActions('categories', ['getCategories']),
    wait: function () {
      setTimeout(() => this.$router.go(), 200);
    },
    submit: async function (name, id_parent) {
      let id = this.$route.params.id;

      let ctg  = this.data;
      id_parent== undefined ? id_parent =ctg.id_parent: id_parent
      id_parent = String(id_parent)
      let category = {
        id: id,
        name: name,
        id_parent:id_parent
      };

    
     
        this.status=''
          this.msg=''

          let result = await this.$store.dispatch("main/editItem", {option:'category', item: category});
    
          if(result.status=='error'){
            this.status='error'
            this.msg= result.message
          }else{ // success
              this.showModal() 
          }
  
    
    },
    editarOtro: function () {
      this.wait();
    },
    salir: function () {
       this.$router.push("/administrador").catch((err) => {});
    },
    showModal() {
      this.$refs["modal-editar"].show();
    },
     changeCategory: function (value, name) {
      this.parent_form = value;
      this.data.parent_name = name;
    
      
    },
  },
};
</script>





